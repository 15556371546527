@charset "UTF-8";
/*
    Theme Name: portfolio 2023
    Description: Dedicated theme
    Version: 1.0.0.0
    Author: Łukasz Książek
    Theme URI: https://
    Author URI: https://
*/
@font-face {
  font-family: "Cabin";
  font-weight: 400;
  font-style: normal;
  src: url("fonts/Cabin-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Cabin";
  font-weight: 700;
  font-style: normal;
  src: url("fonts/Cabin-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "AdventPro";
  font-weight: 400;
  font-style: normal;
  src: url("fonts/AdventPro-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "AdventPro";
  font-weight: 500;
  font-style: normal;
  src: url("fonts/AdventPro-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "AdventPro";
  font-weight: 700;
  font-style: normal;
  src: url("fonts/AdventPro-Bold.ttf") format("truetype");
  font-display: swap;
}
html {
  overflow-y: scroll !important;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: #1E1E1E;
  line-height: 1.4444444444;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: #FFFFFF;
  font-family: "AdventPro", sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.15rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body a {
  transition: all 0.3s;
  color: inherit;
  outline: none;
}

a img {
  border: none;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
}

ul, ol {
  padding-left: 1.5em;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 700;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  color: inherit;
  border-radius: 0;
  box-shadow: none;
  display: block;
}

svg, img:not(.x2) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.d-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.d-table > * {
  display: table-cell;
  vertical-align: middle;
}

.clr {
  clear: both !important;
  float: none !important;
  display: block !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

td {
  width: auto;
  height: auto;
  padding: 0;
}

td:nth-child(n+2) {
  padding-left: 2rem !important;
}
td:nth-child(n+2):empty {
  padding-left: 0 !important;
}

table {
  width: 100% !important;
  height: auto !important;
}

tr {
  width: auto !important;
  height: auto !important;
}

tr:nth-child(n+2) td {
  padding-top: 2rem !important;
}

main {
  display: block;
}

.container {
  margin-left: auto;
  margin-right: auto;
  min-width: 250px;
  display: block;
  max-width: 1280px;
  width: 100%;
  padding-left: 15rem;
  padding-right: 15rem;
}
@media (max-width: 1024px) {
  html:not(.no-rwd) .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  html:not(.no-rwd) .container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

iframe {
  max-width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container embed, .video-container iframe, .video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html {
  overflow-y: auto !important;
  font-size: 0.78125vw;
}
html.show-menu {
  overflow-y: visible !important;
}

body .container {
  max-width: initial;
}

h1, h2, h3, h4, h5, h6, strong {
  color: inherit;
  font-weight: 700;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.6rem;
  grid-row-gap: 0;
}

main {
  padding-top: calc(0rem - 1px);
}

.no-row-gap {
  grid-row-gap: 0 !important;
}

h1, .font48 {
  font-size: 4.8rem;
  line-height: 1.2083333333;
}

h2, .font36 {
  font-size: 4rem;
  line-height: 1.25;
}

h3, .font26 {
  font-size: 2.6rem;
  line-height: 1.3846153846;
}

h4, .font20 {
  font-size: 2rem;
  line-height: 1.5;
}

h5, .font18 {
  font-size: 1.8rem;
  line-height: 1.4444444444;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.big-title {
  padding-top: 3rem;
  padding-bottom: 3rem;
  grid-column: span 12;
  text-align: center;
}

.single-project-container {
  padding-bottom: 3rem;
  grid-column: span 12;
}
.single-project-container > span {
  display: block;
}

.TextLink {
  padding-bottom: 5rem;
}

.plus-button {
  grid-column: 8/span 5;
  margin-top: 3rem;
  margin-left: auto;
  position: relative;
  padding: 0.5rem 1rem;
  display: block;
  width: fit-content;
  text-decoration: none;
}
.plus-button span {
  white-space: nowrap;
}
.plus-button::after {
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 0.2rem;
  height: 0;
  background-color: #fff;
}
.plus-button::before {
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  height: 0.2rem;
  width: 0;
  background-color: #fff;
}
@media (hover: hover) {
  .plus-button:hover::after {
    height: 60%;
  }
  .plus-button:hover::before {
    width: 70%;
  }
}

.displayNone {
  display: none;
}

@media (max-width: 1280px) {
  html:not(.no-rwd) {
    font-size: 10px;
  }
}
@media (max-width: 1024px) {
  html:not(.no-rwd) {
    font-size: 9px;
  }
}
@media (max-width: 767px) {
  html:not(.no-rwd) {
    font-size: 8px;
  }
}
.navigation {
  position: relative;
}
.navigation .container {
  position: relative;
}
.navigation .background-container {
  position: fixed;
  height: 70vh;
  width: 100%;
  overflow: clip;
  z-index: -1;
}
.navigation .background-container .background {
  position: absolute;
  transform-origin: center center;
  transform: perspective(10vw) rotateY(1deg) rotateX(0deg);
  object-position: left center;
}
.navigation .background-container::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .header {
  position: relative;
  padding: 3rem 0;
  width: 100%;
  min-height: 13rem;
  position: fixed;
  z-index: 10;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .header {
    padding: 3rem 0;
  }
}
.navigation .header > .container {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.navigation .header a {
  text-decoration: none;
  cursor: default;
}
.navigation .header a .element {
  width: fit-content;
}
.navigation .header a .element span {
  cursor: pointer;
  position: relative;
  color: transparent;
  background: linear-gradient(to left, #009FFF, #ec2F4B);
  background-clip: text;
  transition-duration: 0.5s;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .header a .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.3rem;
  height: 100%;
  background: #ec2f4b;
  top: 0;
  left: 0;
  clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
}
.navigation .header a .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 0.3rem;
  background: linear-gradient(to left, #009FFF, #ec2F4B);
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}
.navigation .header a .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.3rem;
  height: 100%;
  background: #009FFF;
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 100%, 0 100%, 0 100%, 100% 100%);
}
.navigation .header a .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 0.3rem;
  background: linear-gradient(to left, #009FFF, #ec2F4B);
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
}
.navigation .header a.active.after-render .left-vertical {
  clip-path: polygon(100% 70%, 0 70%, 0 0, 100% 0);
}
.navigation .header a.active.after-render .left-horizontal {
  clip-path: polygon(0 0, 90% 0, 90% 100%, 0% 100%);
}
.navigation .header a.active.after-render .right-vertical {
  clip-path: polygon(100% 100%, 1% 100%, 0 30%, 100% 30%);
}
.navigation .header a.active.after-render .right-horizontal {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%);
}
@media (hover: hover) {
  .navigation .header a.after-render:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .header a.after-render:not(.active) span:hover .left-vertical {
    clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
  }
  .navigation .header a.after-render:not(.active) span:hover .left-horizontal {
    clip-path: polygon(0 0, 80% 0, 80% 100%, 0% 100%);
  }
  .navigation .header a.after-render:not(.active) span:hover .right-vertical {
    clip-path: polygon(100% 100%, 0 100%, 0 100%, 100% 100%);
  }
  .navigation .header a.after-render:not(.active) span:hover .right-horizontal {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 20% 100%);
  }
}
.navigation .header::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.5rem);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content {
  padding: 4rem 0;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding-top: 13rem;
}
.navigation .nav-content a:nth-child(1) {
  transition: all 0.5s;
  text-decoration: none;
  cursor: initial;
}
.navigation .nav-content a:nth-child(1) .element {
  position: relative;
  padding-left: 4rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(1) .element {
    padding-left: 2rem;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(1) .element {
    padding-left: 1rem;
  }
}
.navigation .nav-content a:nth-child(1) .element > span {
  cursor: default;
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .nav-content a:nth-child(1) .element > span::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content a:nth-child(1) .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(1) .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(1) .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a:nth-child(1) .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a.active .left-vertical {
  height: 90%;
}
.navigation .nav-content a.active .left-horizontal {
  width: 95%;
}
.navigation .nav-content a.active .right-vertical {
  height: 90%;
}
.navigation .nav-content a.active .right-horizontal {
  width: 95%;
}
@media (hover: hover) {
  .navigation .nav-content a:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .nav-content a:not(.active) span:hover .left-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .left-horizontal {
    width: 80%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-horizontal {
    width: 80%;
  }
}
.navigation .nav-content a:nth-child(2) {
  transition: all 0.5s;
  text-decoration: none;
  cursor: initial;
}
.navigation .nav-content a:nth-child(2) .element {
  position: relative;
  padding-left: 8rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(2) .element {
    padding-left: 4rem;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(2) .element {
    padding-left: 2rem;
  }
}
.navigation .nav-content a:nth-child(2) .element > span {
  cursor: default;
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .nav-content a:nth-child(2) .element > span::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content a:nth-child(2) .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(2) .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(2) .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a:nth-child(2) .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a.active .left-vertical {
  height: 90%;
}
.navigation .nav-content a.active .left-horizontal {
  width: 95%;
}
.navigation .nav-content a.active .right-vertical {
  height: 90%;
}
.navigation .nav-content a.active .right-horizontal {
  width: 95%;
}
@media (hover: hover) {
  .navigation .nav-content a:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .nav-content a:not(.active) span:hover .left-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .left-horizontal {
    width: 80%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-horizontal {
    width: 80%;
  }
}
.navigation .nav-content a:nth-child(3) {
  transition: all 0.5s;
  text-decoration: none;
  cursor: initial;
}
.navigation .nav-content a:nth-child(3) .element {
  position: relative;
  padding-left: 12rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(3) .element {
    padding-left: 6rem;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(3) .element {
    padding-left: 3rem;
  }
}
.navigation .nav-content a:nth-child(3) .element > span {
  cursor: default;
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .nav-content a:nth-child(3) .element > span::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content a:nth-child(3) .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(3) .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(3) .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a:nth-child(3) .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a.active .left-vertical {
  height: 90%;
}
.navigation .nav-content a.active .left-horizontal {
  width: 95%;
}
.navigation .nav-content a.active .right-vertical {
  height: 90%;
}
.navigation .nav-content a.active .right-horizontal {
  width: 95%;
}
@media (hover: hover) {
  .navigation .nav-content a:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .nav-content a:not(.active) span:hover .left-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .left-horizontal {
    width: 80%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-horizontal {
    width: 80%;
  }
}
.navigation .nav-content a:nth-child(4) {
  transition: all 0.5s;
  text-decoration: none;
  cursor: initial;
}
.navigation .nav-content a:nth-child(4) .element {
  position: relative;
  padding-left: 16rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(4) .element {
    padding-left: 8rem;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(4) .element {
    padding-left: 4rem;
  }
}
.navigation .nav-content a:nth-child(4) .element > span {
  cursor: default;
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .nav-content a:nth-child(4) .element > span::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content a:nth-child(4) .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(4) .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(4) .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a:nth-child(4) .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a.active .left-vertical {
  height: 90%;
}
.navigation .nav-content a.active .left-horizontal {
  width: 95%;
}
.navigation .nav-content a.active .right-vertical {
  height: 90%;
}
.navigation .nav-content a.active .right-horizontal {
  width: 95%;
}
@media (hover: hover) {
  .navigation .nav-content a:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .nav-content a:not(.active) span:hover .left-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .left-horizontal {
    width: 80%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-horizontal {
    width: 80%;
  }
}
.navigation .nav-content a:nth-child(5) {
  transition: all 0.5s;
  text-decoration: none;
  cursor: initial;
}
.navigation .nav-content a:nth-child(5) .element {
  position: relative;
  padding-left: 20rem;
  margin: 0.5rem 0;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(5) .element {
    padding-left: 10rem;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .navigation .nav-content a:nth-child(5) .element {
    padding-left: 5rem;
  }
}
.navigation .nav-content a:nth-child(5) .element > span {
  cursor: default;
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}
.navigation .nav-content a:nth-child(5) .element > span::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
.navigation .nav-content a:nth-child(5) .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(5) .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .nav-content a:nth-child(5) .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a:nth-child(5) .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .nav-content a.active .left-vertical {
  height: 90%;
}
.navigation .nav-content a.active .left-horizontal {
  width: 95%;
}
.navigation .nav-content a.active .right-vertical {
  height: 90%;
}
.navigation .nav-content a.active .right-horizontal {
  width: 95%;
}
@media (hover: hover) {
  .navigation .nav-content a:not(.active) span:hover {
    cursor: pointer;
  }
  .navigation .nav-content a:not(.active) span:hover .left-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .left-horizontal {
    width: 80%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-vertical {
    height: 50%;
  }
  .navigation .nav-content a:not(.active) span:hover .right-horizontal {
    width: 80%;
  }
}
.navigation .langButton {
  cursor: pointer;
  position: relative;
  width: fit-content;
  padding: 0.5rem 2rem;
  left: 0;
}
.navigation .langButton::after {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  mask: radial-gradient(ellipse at 50% 50%, rgb(0, 0, 0) 44%, rgba(255, 255, 255, 0) 90%);
  z-index: -1;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .navigation .langButton {
    margin-left: 0;
  }
}
.navigation .langButton .left-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .langButton .left-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
}
.navigation .langButton .right-vertical {
  transition: all 0.5s;
  position: absolute;
  width: 0.2rem;
  height: 0;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
.navigation .langButton .right-horizontal {
  transition: all 0.5s;
  position: absolute;
  width: 0;
  height: 0.2rem;
  background-color: #FFFFFF;
  bottom: 0;
  right: 0;
}
@media (hover: hover) {
  .navigation .langButton:hover .left-vertical {
    height: 50%;
  }
  .navigation .langButton:hover .left-horizontal {
    width: 80%;
  }
  .navigation .langButton:hover .right-vertical {
    height: 50%;
  }
  .navigation .langButton:hover .right-horizontal {
    width: 80%;
  }
}

main {
  margin-top: 10rem;
}
main .container .content {
  border-radius: 1rem;
  min-height: 30vh;
  background: rgba(36, 36, 36, 0.949);
  box-shadow: 0 0 3rem 4rem rgba(36, 36, 36, 0.949);
}

.skills {
  margin-bottom: 5rem;
}
.skills .grid {
  align-items: end;
}
.skills .skill-title {
  padding-top: 3rem;
  grid-column: 2/span 3;
  margin-left: auto;
}
@media (max-width: 1024px) {
  html:not(.no-rwd) .skills .skill-title {
    grid-column: 1/span 3;
  }
}
@media (max-width: 767px) {
  html:not(.no-rwd) .skills .skill-title {
    grid-column: 1/span 5;
  }
}
.skills .skill-cont {
  grid-column: 5/span 6;
  background-color: rgb(158, 156, 156);
  height: 3.6rem;
}
@media (max-width: 1024px) {
  html:not(.no-rwd) .skills .skill-cont {
    grid-column: 4/span 7;
  }
}
@media (max-width: 767px) {
  html:not(.no-rwd) .skills .skill-cont {
    grid-column: 6/span 6;
  }
}
.skills .skill-cont .progress {
  position: relative;
  height: 100%;
}
.skills .skill-cont .progress .anim-cont {
  transition: 1s all ease-in-out 0s;
  height: 100%;
  width: 0;
  background-color: #009FFF;
}

.projects {
  margin-bottom: 5rem;
}
.projects .cat-container {
  grid-column: span 12;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.projects .cat-container .singleCat {
  transition: all 0.25s ease-in-out 0s;
  cursor: pointer;
}
.projects .cat-container .singleCat.active {
  text-shadow: 0px 0px 0.5rem #FFFFFF;
}
@media (hover: hover) {
  .projects .cat-container .singleCat:hover {
    text-shadow: 0px 0px 0.5rem #FFFFFF;
  }
}
.projects .projects-container {
  grid-column: span 12;
  padding-top: 6rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 5rem 1.6rem;
}

.single-project {
  cursor: pointer;
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr auto auto;
  grid-gap: 1.6rem;
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .single-project {
    grid-template-rows: auto auto 1fr;
  }
}
.single-project .cont-img {
  grid-column: span 6;
  grid-row: span 3;
  width: auto;
  aspect-ratio: 4/3;
  overflow: clip;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .single-project .cont-img {
    grid-column: 1/span 10;
    grid-row: 1/span 2;
  }
}
@media (max-width: 599px) {
  html:not(.no-rwd) .single-project .cont-img {
    grid-column: 1/span 11;
  }
}
.single-project .cont-img img {
  transition: all 0.3s ease-in-out 0s;
  transform-origin: top center;
  display: block;
  object-position: top center;
}
.single-project .desc-container {
  grid-column: 8/span 6;
  grid-row: span 3;
  display: flex;
  flex-direction: column;
}
.single-project .desc-container .button-in {
  transition: all 0.5s ease-in-out 0s;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .single-project .desc-container {
    grid-column: 5/span 8;
    grid-row: 2/span 2;
    background: linear-gradient(180deg, transparent 2%, rgba(36, 36, 36, 0.647) 12%);
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
    z-index: 10;
  }
}
@media (max-width: 599px) {
  html:not(.no-rwd) .single-project .desc-container {
    grid-column: 2/span 11;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .single-project .desc-container {
    margin-top: -3rem;
  }
}
@media (hover: hover) {
  .single-project:hover .cont-img img {
    transform: scale(1.1);
  }
  .single-project:hover .plus-button::after {
    height: 60%;
  }
  .single-project:hover .plus-button::before {
    width: 70%;
  }
}
.single-project .title-project {
  margin-bottom: 3rem;
}
.single-project .content-project {
  grid-column: 8/span 5;
  max-height: 6.5em;
  overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: auto;
}

.custom-slider {
  margin-bottom: 5rem;
  position: relative;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .custom-slider {
    margin-bottom: 2rem;
  }
}
.custom-slider .slider-content {
  position: relative;
  overflow: clip;
}
.custom-slider .single-project {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.4s ease-in-out 0s, opacity 0s linear 0s;
  transform: translateX(200%);
  padding-left: 1.6rem;
  margin-left: -1.6rem;
  opacity: 0;
}
.custom-slider .current-el {
  position: relative;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.4s ease-in-out 0s, opacity 0s linear 0.45s;
}
.custom-slider .nx-el {
  transform: translateX(100%);
  opacity: 1;
  transition: transform 0.4s ease-in-out 0s, opacity 0s linear 0.45s;
}
.custom-slider .prev-el {
  transform: translateX(-100%);
  opacity: 1;
  transition: transform 0.4s ease-in-out 0s, opacity 0s linear 0.45s;
}
.custom-slider .arr-prev,
.custom-slider .arr-next {
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  background-color: rgba(126, 126, 126, 0.647);
  cursor: pointer;
}
.custom-slider .arr-prev.disabled,
.custom-slider .arr-next.disabled {
  opacity: 0;
  pointer-events: none;
}
.custom-slider .arr-next {
  right: 0;
}
@media (hover: hover) {
  .custom-slider .arr-next:hover {
    transform: translate(0.5rem, -50%);
  }
}
.custom-slider .arr-prev {
  left: 0;
}
@media (hover: hover) {
  .custom-slider .arr-prev:hover {
    transform: translate(-0.5rem, -50%);
  }
}

.section-quote {
  margin-bottom: 2rem;
}
.section-quote .quote {
  grid-column: 3/span 8;
  line-height: 1.8;
  text-align: center;
  border: 0.3rem solid;
  margin: 2rem 0;
  padding: 1.6rem 2.2rem;
  border-image-slice: 1;
  animation: anim-border 5s infinite;
  clip-path: polygon(0 0, 75% 0, 75% 0.5rem, calc(100% - 0.5rem) 0.5rem, calc(100% - 0.5rem) 25%, 100% 25%, 100% 100%, 25% 100%, 25% calc(100% - 0.5rem), 0.5rem calc(100% - 0.5rem), 0.5rem 75%, 0 75%);
}
@media (max-width: 767px) {
  html:not(.no-rwd) .section-quote .quote {
    grid-column: 2/span 10;
    line-height: 1.6;
    animation: none;
  }
}
@media (max-width: 374px) {
  html:not(.no-rwd) .section-quote .quote {
    grid-column: span 12;
  }
}

@keyframes anim-border {
  0% {
    border-image-source: linear-gradient(to right, #fff, #009FFF);
  }
  66% {
    border-image-source: linear-gradient(to right, #009FFF, #fff);
  }
  100% {
    border-image-source: linear-gradient(to right, #fff, #009FFF);
  }
}
.slider-tech {
  margin-bottom: 5rem;
}

.custom-slider-tech-container {
  position: relative;
  margin-bottom: 5rem;
}
.custom-slider-tech-container .slider-wrap {
  overflow: clip;
}
.custom-slider-tech-container .container-slider-in {
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease-in-out 0s;
}
.custom-slider-tech-container .container-slider-in > .element-in {
  height: 21rem;
  flex-shrink: 0;
}
@media (max-width: 599px) {
  html:not(.no-rwd) .custom-slider-tech-container .container-slider-in > .element-in {
    height: 15rem;
  }
}
.custom-slider-tech-container .container-slider-in > .element-in:not(:last-child) {
  padding-right: 3rem;
}
.custom-slider-tech-container .container-slider-in .img-cont {
  width: 100%;
  height: 100%;
}
.custom-slider-tech-container .arr-prev,
.custom-slider-tech-container .arr-next {
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  background-color: rgba(126, 126, 126, 0.647);
  cursor: pointer;
}
.custom-slider-tech-container .arr-prev.disabled,
.custom-slider-tech-container .arr-next.disabled {
  opacity: 0;
  pointer-events: none;
}
.custom-slider-tech-container .arr-next {
  right: 0;
}
@media (hover: hover) {
  .custom-slider-tech-container .arr-next:hover {
    transform: translate(0.5rem, -50%);
  }
}
.custom-slider-tech-container .arr-prev {
  left: 0;
}
@media (hover: hover) {
  .custom-slider-tech-container .arr-prev:hover {
    transform: translate(-0.5rem, -50%);
  }
}

.strong-points {
  margin-bottom: 5rem;
  position: relative;
  z-index: 2;
}
.strong-points .back-container {
  position: absolute;
  z-index: -1;
  width: 50%;
  height: 100%;
}
.strong-points .back-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 200%;
  height: 100%;
  background-color: rgba(14, 4, 48, 0.647);
  top: 0;
  left: 0;
}
.strong-points .custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem 3rem;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .strong-points .custom-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (max-width: 599px) {
  html:not(.no-rwd) .strong-points .custom-grid {
    grid-template-columns: 1fr;
  }
}
.strong-points .custom-grid .wrap {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 2rem;
}
.strong-points .custom-grid .ico-container {
  height: 3.6rem;
  aspect-ratio: 1;
}
.strong-points .custom-grid .single-point-container {
  padding: 1rem;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .strong-points .custom-grid .single-point-container {
    transition-delay: 0s !important;
  }
}
@media (max-width: 767px) {
  html:not(.no-rwd) .strong-points .custom-grid .single-point-container:nth-child(3) {
    grid-column: span 2;
    width: 50%;
  }
}
@media (max-width: 599px) {
  html:not(.no-rwd) .strong-points .custom-grid .single-point-container:nth-child(3) {
    grid-column: span 1;
    width: 100%;
  }
}

#footer {
  margin: 5rem 0 3rem;
}
#footer .copyRights {
  padding-top: 3rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  gap: 0 2rem;
  align-items: center;
}
#footer .copyRights::after {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  display: block;
  background-color: #B4BFE0;
}
@media (max-width: 599px) {
  html:not(.no-rwd) #footer .copyRights a {
    grid-row: 1/span 2;
  }
  html:not(.no-rwd) #footer .copyRights span:nth-child(2) {
    grid-column: 2/span 2;
    grid-row: 2;
  }
  html:not(.no-rwd) #footer .copyRights span:nth-child(3) {
    grid-column: 2/span 2;
    grid-row: 1;
  }
}
#footer .copyRights .cont-img {
  width: 8rem;
  height: 8rem;
  transition: all 0.2s ease-in-out 0s;
}
#footer .copyRights .cont-img svg path {
  transition: all 0.2s ease-in-out 0s;
  fill: #F2F6FF;
}
@media (hover: hover) {
  #footer .copyRights .cont-img:hover {
    transform: scale(1.1);
  }
  #footer .copyRights .cont-img:hover svg path {
    fill: #B4BFE0;
  }
}
#footer .copyRights .plus-button {
  margin-top: 0;
}
#footer .copyRights .rights {
  padding: 0.5rem 1rem;
  margin-left: auto;
}

.section-single-project a {
  text-decoration: underline;
  text-decoration-color: #FFFFFF;
}
.section-single-project a:hover {
  color: #009FFF;
  text-decoration-color: #009FFF;
}
.section-single-project .single-project-container img.left {
  width: 60%;
  float: left;
  padding-right: 2rem;
  padding-top: 2rem;
}
.section-single-project .single-project-container img.right {
  width: 60%;
  float: right;
  padding-left: 2rem;
  padding-top: 2rem;
}
.section-single-project .single-project-container img.center {
  padding-top: 2rem;
}
.section-single-project .single-project-container span {
  display: block;
  padding-top: 2rem;
}
@media (max-width: 767px) {
  html:not(.no-rwd) .section-single-project .single-project-container img {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    display: block;
    float: none;
  }
}

.about {
  margin-bottom: 5rem;
}
.about a {
  display: block;
  text-decoration: underline;
  text-decoration-color: #FFFFFF;
}
.about a:hover {
  color: #009FFF;
  text-decoration-color: #009FFF;
}
.about .name {
  margin-top: 1.2rem;
}
.about .mail-container {
  margin-top: 1.2rem;
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}
.about .mail-container img {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.anim-left {
  transition: all 1s;
  opacity: 0;
  transform: translate(-6rem, 0);
}

.anim-right {
  transition: all 1s;
  opacity: 0;
  transform: translate(1rem, 2rem);
}

.anim-bottom {
  transition: all 1s;
  opacity: 0;
  transform: translate(0, 3rem);
}

.anim-top {
  transition: all 1s;
  opacity: 0;
  transform: translate(0, -2rem);
}

.anim-center {
  transition: all 0.7s;
  opacity: 0;
}

.anim-project {
  transition: all 0.7s;
  opacity: 0;
}

.anim-quote {
  transition: all 1.1s;
  opacity: 0;
  filter: blur(1rem);
}

.fade {
  opacity: 1;
  transform: translate(0, 0);
  filter: blur(0);
}

.fade-width {
  width: 100% !important;
}/*# sourceMappingURL=style.css.map */